import { render, staticRenderFns } from "./bannerdetail.vue?vue&type=template&id=21661fbe&scoped=true&"
import script from "./bannerdetail.vue?vue&type=script&lang=js&"
export * from "./bannerdetail.vue?vue&type=script&lang=js&"
import style0 from "./bannerdetail.vue?vue&type=style&index=0&id=21661fbe&prod&scoped=true&lang=css&"
import style1 from "./bannerdetail.vue?vue&type=style&index=1&id=21661fbe&prod&lang=css&"
import style2 from "./bannerdetail.vue?vue&type=style&index=2&id=21661fbe&prod&lang=css&"
import style3 from "./bannerdetail.vue?vue&type=style&index=3&id=21661fbe&prod&lang=css&"
import style4 from "./bannerdetail.vue?vue&type=style&index=4&id=21661fbe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21661fbe",
  null
  
)

export default component.exports