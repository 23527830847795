<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title"> </div>
			<div class="add_main hidden_scroll">
				<div class="sub_content">
					<div class="">
						<el-form
							label-position="right"
							label-width="120px"
							:model="formData"
							:rules="rule"
							ref="formData"
						>
							<div class="margin_top_40 font_weight_bold">banner信息</div>
							<el-form-item
								label="Banner展示位"
								prop="type"
								class="margin_top_20"
							>
								<el-select
									v-model="formData.type"
									placeholder=""
									:disabled="$route.query.type == 'detail'"
								>
									<el-option label="首页顶部banner" value="1"></el-option>
									<el-option label="专栏页顶部banner" value="2"></el-option>
									<el-option label="活动首页顶部banner" value="3"></el-option>
									<el-option label="专题顶部banner" value="4"></el-option>
									<el-option label="香港专区顶部banner" value="5"></el-option>
									<el-option label="美国专区顶部banner" value="6"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="banner标题" prop="title">
								<el-input
									v-model="formData.title"
									:disabled="$route.query.type == 'detail'"
								>
								</el-input>
							</el-form-item>

							<el-form-item label="banner简介" prop="illustrate">
								<el-input
									v-model="formData.desc"
									:disabled="$route.query.type == 'detail'"
								>
								</el-input>
							</el-form-item>
							<el-form-item label="跳转链接" prop="url">
								<el-input
									v-model="formData.url"
									:disabled="$route.query.type == 'detail'"
								></el-input>
							</el-form-item>

							<el-form-item label="封面图片" prop="pic">
								<p style="color: red; font-size: 18px"
									>（尺寸要求3450 * 1400px）</p
								>

								<div class="upload_img_div" v-if="formData.pic">
									<img
										style="width: 80%"
										:src="formData.pic"
										@click="uploadPicture(formData.pic)"
									/>
									<div class="upload_img_del">
										<img
											@click="removePicture"
											src="../../../assets/images/upload_img_del.png"
										/>
									</div>
								</div>

								<div v-else>
									<div class="el-upload__text">
										<img
											style="width: 100px"
											src="../../../assets/images/uploadImg.png"
											alt=""
											@click="uploadPicture(formData.pic)"
										/>
										<br /><em>点击加号上传</em></div
									>
								</div>
								<!-- 剪裁组件弹窗 -->
								<el-dialog
									title=""
									:visible.sync="cropperModel"
									width="1300px"
									center
									:append-to-body="true"
								>
									<template v-if="cropperModel">
										<cropper-image-new
											:Name="cropperName"
											:type="scale"
											:showType="3"
											@uploadImgSuccess="handleUploadSuccess"
											ref="child"
										>
										</cropper-image-new
									></template>
								</el-dialog>
							</el-form-item>
							<el-form-item label="是否上架">
								<el-radio
									v-model="formData.is_show"
									label="1"
									:disabled="$route.query.type == 'detail'"
									>是</el-radio
								>
								<el-radio
									v-model="formData.is_show"
									label="0"
									:disabled="$route.query.type == 'detail'"
									>否</el-radio
								>
							</el-form-item>

							<!-- <el-form-item label="比重" prop="score">
								<el-input-number
									v-model="formData.score"
									:min="1"
									:max="99"
									label="比重1-99"
									:disabled="$route.query.type == 'detail'"
								></el-input-number>
							</el-form-item> -->
							<el-form-item>
								<div class="flex justify_content_c align_items_c">
									<el-button
										type="primary"
										class="custom_btn_width_100"
										@click="submit('formData')"
										v-if="$route.query.type != 'detail'"
										>保存</el-button
									>
									<el-button class="custom_btn_width_100" @click="back"
										>返回</el-button
									>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</el-card>
		<div v-if="loading" class="loading-overlay">
			<div class="spinner"></div>
		</div>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
import { bannerUpdate, bannerDetail } from "@/api/article";

import { validateNull } from "@/utils/validate";

export default {
	mixins: [myMixin],
	name: "",
	components: {},
	data() {
		return {
			props: { multiple: false },
			rule: {
				title: [{ validator: validateNull, trigger: "blur", required: true }],
				pic: [{ validator: validateNull, trigger: "change", required: true }],
				type: [{ validator: validateNull, trigger: "change", required: true }],
				score: [{ validator: validateNull, trigger: "change", required: true }],
			},
			loading: false,
			formData: {
				title: "",
				desc: "",
				url: "",
				pic: "",
				type: "",
				is_show: "1",
				score: "1",
			},
			//参数
			cropperModel: false,
			cropperName: "",
			scale: "0",
		};
	},
	watch: {
		"$route.query.id": [
			function handler1() {
				if (!this.$route.query.id) {
					this.clearFormAdd();
				} else {
					this.bannerDetail();
				}
			},
		],
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.bannerDetail();
		}
	},
	methods: {
		removePicture() {
			this.formData.pic = "";
		},
		//封面设置
		uploadPicture(name) {
			if (this.$route.query.type == "detail") {
				// 详情不支持编辑图片
			} else {
				this.cropperName = name;
				this.type = "1";
				this.cropperModel = true;
			}
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			this.cropperModel = false;
			this.formData.pic = data.url;
		},

		// 新增资讯提交
		submit(formName) {
			let formData = this.formData;
			if (formData.title && formData.title.length > 500) {
				this.$message.error("标题过长，请输入500以内内容");
				return;
			} else if (formData.desc && formData.desc.length > 500) {
				this.$message.error("描述过长，请输入500以内内容");
				return;
			} else if (formData.pic && formData.pic.length > 1000) {
				this.$message.error("图片链接过长，请输入1000以内内容");
				return;
			} else if (formData.url && formData.url.length > 500) {
				this.$message.error("原文链接过长，请输入500以内内容");
				return;
			}
			this.$refs[formName].validate((valid) => {
				const that = this;
				if (valid) {
					this.loading = true;
					bannerUpdate(formData)
						.then((res) => {
							this.loading = false;
							if (res.code == 200) {
								that.$router.push({ path: "/recommend/bannerlist" });
							} else {
							}
						})
						.catch((error) => {
							this.loading = false;
						});
				} else {
					that.$message.error("缺少必填参数，请根据提示填写！");
					return false;
				}
			});
		},
		// 获取资讯详情
		bannerDetail() {
			const that = this;
			bannerDetail({ id: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						for (let key in that.formData) {
							that.formData[key] = res.data[key];
						}
						that.formData["id"] = res.data.id;
						that.formData.type = that.formData.type
							? that.formData.type.toString()
							: that.formData.type;

						that.formData.is_show = that.formData.is_show.toString();
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		back() {
			this.$confirm("返回将丢失已经修改的数据，确认返回吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$router.push({ path: "/recommend/bannerlist" });
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消返回",
					});
				});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
			this.formData.is_show = "0";
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}
.upload_img_div {
	display: flex;
}
.upload_img_del {
	margin-left: -15px;
	margin-top: -15px;
}
.upload_img_del img {
	width: 30px;
	height: 30px;
}
.add_main {
	width: 100%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}

.sub_content {
	margin-left: 15%;
	margin-right: 15%;
}
</style>
<style>
.ql-snow .ql-picker {
	line-height: 24px;
}
.ql-container {
	height: 400px;
	overflow: auto;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
	content: "黑体";
	font-family: "SimHei";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Microsoft-YaHei"]::before {
	content: "微软雅黑";
	font-family: "Microsoft YaHei";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
	content: "楷体";
	font-family: "KaiTi";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
	content: "仿宋";
	font-family: "FangSong";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
	content: "Arial";
	font-family: "Arial";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Times-New-Roman"]::before {
	content: "Times New Roman";
	font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
	content: "sans-serif";
	font-family: "sans-serif";
}

.ql-font-SimSun {
	font-family: "SimSun";
}

.ql-font-SimHei {
	font-family: "SimHei";
}

.ql-font-Microsoft-YaHei {
	font-family: "Microsoft YaHei";
}

.ql-font-KaiTi {
	font-family: "KaiTi";
}

.ql-font-FangSong {
	font-family: "FangSong";
}

.ql-font-Arial {
	font-family: "Arial";
}

.ql-font-Times-New-Roman {
	font-family: "Times New Roman";
}

.ql-font-sans-serif {
	font-family: "sans-serif";
}

/* 字号设置 */
/* 默认字号 */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: "14字号";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
	content: "14字号" !important;
	font-size: 14px;
}

.ql-size-14px {
	font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
	content: "16字号";
	font-size: 16px;
}

.ql-size-16px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
	content: "18字号";
	font-size: 16px;
}

.ql-size-18px {
	font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
	content: "20字号";
	font-size: 16px;
}

.ql-size-20px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
	content: "22字号";
	font-size: 16px;
}

.ql-size-22px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
	content: "26字号";
	font-size: 16px;
}

.ql-size-26px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
	content: "28字号";
	font-size: 16px;
}

.ql-size-28px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
	content: "30字号";
	font-size: 16px;
}

.ql-size-30px {
	font-size: 16px;
}
</style>
<style>
.quill-editor {
	margin-left: -130px;
}
.ql-container {
	height: 800px;
}
.ql-editor ul li {
	font-size: 14px;
	margin-bottom: 20px;
	font-weight: 400;
	line-height: 21px;
}
.ql-editor ul li::before {
	font-size: 20px;
	vertical-align: middle;
	font-weight: 400;
}

.ql-editor p {
	text-align: justify;
	margin-top: 20px;
	font-size: 18px;
}

.ql-editor p img,
.ql-editor p span img {
	margin-top: 10px;
	margin-bottom: 10px;
}
.ql-editor p p,
.ql-editor p a,
.ql-editor p em,
.ql-editor blockquote span,
.ql-editor p blockquote span,
.ql-editor p span {
	font-size: 18px;
	font-weight: 400;
	line-height: 36px;
}

.ql-editor blockquote {
	padding-top: 24px;
	padding-bottom: 20px;
	background: #fafafa;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 字段三级 */
	color: #b2b2b2;
}

.ql-editor h1,
.ql-editor h1 * {
	font-size: 26px !important;
	font-weight: bold !important;
	color: #0056ff !important;
	display: flex;
	background: #e7efff;
}
.ql-editor h1 {
	margin-top: 40px !important;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
}

.ql-editor h2,
.ql-editor h2 * {
	font-size: 22px !important;
	font-weight: bold !important;
	color: #0056ff !important;
	display: flex;
	background: #e7efff;
}

.ql-editor h2 {
	margin-top: 30px !important;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
}
.ql-editor h3,
.ql-editor h3 * {
	font-size: 20px !important;
	font-weight: bold !important;
	color: #0056ff !important;
	display: flex;
	background: #e7efff;
}
.ql-editor h3,
.ql-editor h3 * {
	margin-top: 25px !important;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
}
.ql-toolbar {
	background: white;
	width: 960px;
	margin-left: 0px;
}
@media screen and (max-width: 1300px) {
	.ql-toolbar {
		margin-left: -130px;
	}
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
	border: 1px solid gray;
}
.card_title {
	height: 40px;
}
</style>
<style>
.el-card__body,
.el-main {
	padding-top: 0 !important;
}
</style>
<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	/* 样式可以根据需求自行调整 */
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top: 4px solid #fff;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
